<template>
  <div class="contract-tab-container">
    <div class="cm-flex-container" v-if="showForm">
      <div class="cm-select-container">
        <div class="ew-field-label">Reporting Unit Id:
        </div>
        <Select
            :name="'Select Reporting Unit Id'"
            v-model="selectedReportingUnitId"
            :allOptions="this.reportingUnitIdList"
            :placeholderText="'Select Contract Id'"
            :selectedText="'columns'"
            :disabled="loggedUserHierarchyLevel === 3"
            @change="handleSelect"
            />
      </div>
    <div class="cm-btn-align-right">
            <Button
              class="edit-btn"
              @click="setUpEditSection()"
              :label="$t(`Edit Details`)" :color="'primary'"
              :disabled="!(loggedUserHierarchyLevel === 2 && allowContractEdit)"
              v-if="!editSection"
            />
            <Button
              class="cancel-btn"
              @click="handleCancelBtnClick()"
              :type="'outlined'"
              :label="$t(`cancel`)" :color="'primary'"
              :disabled="!(loggedUserHierarchyLevel === 2 && allowContractEdit)"
              v-else
            />
        </div>
    </div>
  <div v-if="showForm">
    <Form class = "float-left" :key="getFormName()" :name="getFormName()"
    :getFormUrlParams="'idName=ReportingUnitId&idValue=' + reportingUnitId" :loadFormData="true" :isEditing="this.editSection"/>
  </div>
  </div>
</template>

<script>
import Form from '../../../../shared/components/Form.vue'
import Button from '../../../../shared/components/Button.vue'
import Select from '../../../../shared/components/Select.vue'
import EventBus from '@/app/shared/event-bus.js'
import { mapState, mapActions } from 'vuex'
import { isNullOrUndefined } from '../../../../shared/utils/Objects'

export default {
  name: 'ContractTab',
  data: () => ({
    reportingUnitIdList: []
  }),
  components: {
    Form,
    Button,
    Select
  },
  props: {
    editSection: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showForm () {
      return !isNullOrUndefined(this.reportingUnitId)
    },
    selectedReportingUnitId () {
      if (this.reportingUnitId) {
        return ({ Key: this.reportingUnitId, Value: this.reportingUnitId })
      }
      return null
    },
    ...mapState('ContractManagement', ['reportingUnitId', 'allowContractEdit', 'loggedUserHierarchyLevel'])
  },
  created () {
    EventBus.$on('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  methods: {
    getFormName () {
      return (this.editSection) ? 'EditContractV2' : 'ViewContract'
    },
    setUpEditSection () {
      this.editSection = true
    },
    handleCancelBtnClick () {
      this.editSection = false
    },
    formSavedSuccessfully ({ name, response }) {
      this.editSection = false
    },
    async handleSelect (value) {
      if (value && value.Key) {
        this.$router.push('/dashboard/contract/' + value.Key + '/targetedDetails')
      }
    },
    async getReportingUnitIdList () {
      const data = await this.fetchReportingUnitIdList({ reportingUnitId: this.reportingUnitId })
      this.reportingUnitIdList = data.reportingUnitId.filter(ele => !!ele).map((ele, id) => ({ Key: ele, Value: ele }))
    },
    ...mapActions('ContractManagement', ['setReportingUnitId', 'fetchReportingUnitIdList'])
  },
  async mounted () {
    if (!isNullOrUndefined(this.reportingUnitId)) {
      this.id = this.$route.params.id
      this.selectedReportingUnitId = ({ Key: this.id, Value: this.id })
      this.setReportingUnitId(this.id)
    }
    this.getReportingUnitIdList()
  }
}
</script>

<style>
  .contract-tab-container {
    margin-top: 15px;
  }
</style>
